import React from 'react';
import { useEffect, useState } from 'react'
import axios from 'axios';
import { useParams} from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import './CRUD.css';

const EditImage = () => {

    let { id, role } = useParams();

    const {setValue } = useForm();
    const [djasa, setdJasa] = useState([]);
   
    const isAddMode = !id;
    const fields = [
        'id',
    ];

    useEffect(() => {
        if (!isAddMode) {
            fetchJasa(id);
        }
    }, [isAddMode]);


    const fetchJasa = async (id) => {
        try {
            const result = await axios(`https://asaonliserver.my.id/jasa/${id}`);

            await setdJasa(result.data.jasa);
            fields.forEach((field) => setValue(field, result.data.jasa[field]));
        } catch (err) {
            console.log(err.response.data);
        }
    };

    const confirmDelete = async (id) => {
        const payload = {
            id: id.toString(),
        };
        await axios.post(
            'https://asaonliserver.my.id/admin/gambar/delete',
            JSON.stringify(payload)
        );
        window.location.reload();
    };


    return (
        <>
            {`${role}` === "d1065c01abfbfd0e6b04120cf4ad9e1f" && (
                <div className='edit-jasagambar'>
                    <h2>Edit Gambar</h2>
                    {djasa.imagejasa && Object.entries(djasa.imagejasa).map(([key, value]) => (
                        <div className='gambar-edit' key={key}>
                            <img
                                src={`https://asaonliserver.my.id/uploads/${djasa.imagejasa[key]}`}
                                className='edit-gambar'
                            />
                            <Link to={`/editgambarjasa/${djasa.id}/${role}/${key}`} className='editimagejs'>Edit</Link>
                            <span
                                className='kategori-ebox-delete'
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                    if (window.confirm('Are you sure?')) {
                                        confirmDelete(key);
                                    }
                                }}
                            >
                                Delete
                            </span>
                        </div>
                    ))}
                </div>
            )}
        </>
    );

};

export default EditImage

