import React from 'react';
import { useForm } from 'react-hook-form';

import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

const TambahProvinsi = () => {

    let { role } = useParams();
    const { register, handleSubmit } = useForm();
    const navigate = useNavigate();

    const onSubmit = async (data) => {
        let dataJSON = JSON.stringify(data, (k, v) =>
            v && typeof v === 'object' ? v : '' + v
        );
        let payload = JSON.parse(dataJSON);


        const result = await axios.post(
            `https://asaonliserver.my.id/admin/provinsi/add`,
            JSON.stringify(payload)
        );
        console.log(result.data);

        // resetForm();
        navigate(`/allprovinsi/${role}`);
    };

    return (
        <>
            {`${role}` === "d1065c01abfbfd0e6b04120cf4ad9e1f" && (
                <div className='filters-inputs'>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className='mb-3'>
                            <label htmlFor='' className='form-label'>
                                Nama Provinsi
                            </label>
                            <input
                                type='text'
                                className='admin-filterinput'
                                id='namaprovinsi'
                                name='namaprovinsi'
                                {...register('namaprovinsi', { required: true })}
                            />
                        </div>
                        <button className='save-filters' type='submit'>
                            Save
                        </button>
                    </form>
                </div>
            )}
        </>
    );

};

export default TambahProvinsi