import React from 'react';

import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

const EditRelasi = () => {

    let { id, role } = useParams();
    const { register, handleSubmit, setValue } = useForm();
    const [kategori, setKategori] = useState([]);
    const [subkategori, setSubKategori] = useState([]);
    const [provinsi, setProvinsi] = useState([]);
    const [kota, setKota] = useState([]);
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null)
    const [djasa, setdJasa] = useState([]);
    const isAddMode = !id;
    const fields = [
        'id',
        'title',
        'alamat',
        'description',
        'harga',
        'wa',
        'ig', ,
    ];

    useEffect(() => {
        if (!isAddMode) {
            fetchJasa(id);
        }
    }, [isAddMode]);


    const fetchJasa = async (id) => {
        try {
            const result = await axios(`https://asaonliserver.my.id/jasa/${id}`);

            await setdJasa(result.data.jasa);
            fields.forEach((field) => setValue(field, result.data.jasa[field]));
        } catch (err) {
            console.log(err.response.data);
        }
    };

    useEffect(() => {

        const fetchKategori = async () => {
            try {
                const result2 = await axios(`https://asaonliserver.my.id/kategori`);
                await setKategori(result2.data.kategori);
                setLoaded(true);
            } catch (err) {
                setErrorMessage(err.response.data)
            }
        };
        fetchKategori();

        const fetchSubKategori = async () => {
            try {
                const result3 = await axios(`https://asaonliserver.my.id/subkategori`);
                await setSubKategori(result3.data.subkategori);
                setLoaded(true);
            } catch (err) {
                setErrorMessage(err.response.data)
            }
        };
        fetchSubKategori();

        const fetchProvinsi = async () => {
            try {
                const result4 = await axios(`https://asaonliserver.my.id/provinsi`);
                await setProvinsi(result4.data.provinsi);
                setLoaded(true);
            } catch (err) {
                setErrorMessage(err.response.data)
            }
        };
        fetchProvinsi();

        const fetchKota = async () => {
            try {
                const result5 = await axios(`https://asaonliserver.my.id/kota`);
                await setKota(result5.data.kota);
                setLoaded(true);
            } catch (err) {
                setErrorMessage(err.response.data)
            }
        };
        fetchKota();
    }, []);

    const onSubmit = async (data) => {
        let dataJSON = JSON.stringify(data, (k, v) =>
            v && typeof v === 'object' ? v : '' + v
        );
        let payload = JSON.parse(dataJSON);

        const result6 = await axios.post(
            'https://asaonliserver.my.id/admin/relasi/add',
            JSON.stringify(payload)
        );
        console.log(result6.data);


        navigate(`/detail/${id}/${role}`);
    };

    return (
        <>
            {`${role}` === "d1065c01abfbfd0e6b04120cf4ad9e1f" && (
                <div className='relasi-u'>
                    <h2>Tambah Relasi</h2>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <h2>{djasa.title}</h2>

                        <div className="row">
                            <select id='idktg' className="option-filter" {...register('idktg', { required: true })}
                            >

                                <option value='0' className='katego-op'>Pilih</option>

                                {
                                    kategori.map(kategoris => (
                                        <option value={kategoris.id} key={kategoris.id} className='katego-op'>
                                            {kategoris.namakategori}
                                        </option>
                                    ))
                                }

                            </select >
                            <select id='idsktg' className="option-filter" {...register('idsktg', { required: true })}
                            >

                                <option value='0' className='katego-op'>Pilih</option>

                                {
                                    subkategori.map(subkategoris => (
                                        <option value={subkategoris.id} key={subkategoris.id} className='katego-op'>
                                            {subkategoris.namasubkategori}
                                        </option>
                                    ))
                                }

                            </select >
                            <select id='idprv' className="option-filter" {...register('idprv', { required: true })}
                            >

                                <option value='0' className='katego-op'>Pilih</option>

                                {
                                    provinsi.map(provinsis => (
                                        <option value={provinsis.id} key={provinsis.id} className='katego-op'>
                                            {provinsis.namaprovinsi}
                                        </option>
                                    ))
                                }

                            </select >
                            <select id='idkot' className="option-filter" {...register('idkot', { required: true })}
                            >

                                <option value='0' className='katego-op'>Pilih</option>

                                {
                                    kota.map(kotas => (
                                        <option value={kotas.id} key={kotas.id} className='katego-op'>
                                            {kotas.namakota}
                                        </option>
                                    ))
                                }

                            </select >
                            <button className='button-filter' type='submit'>
                                Save
                            </button>
                        </div>
                    </form>
                </div>
            )}
        </>
    );

};

export default EditRelasi