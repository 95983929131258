import React from 'react';

import { useForm } from 'react-hook-form';
import { useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

const EditProvinsi = () => {

    let { id, role } = useParams();
    const { register, handleSubmit, setValue } = useForm();
    const navigate = useNavigate();
   
    const isAddMode = !id;
    const fields = [
        'id',
        'namaprovinsi',
    ];

    useEffect(() => {
		if (!isAddMode) {
			fetchProvinsi(id);
		}
	}, [isAddMode]);
    

    const fetchProvinsi = async (id) => {
        try {
            const result = await axios(`https://asaonliserver.my.id/oneprovinsi/${id}`);

            fields.forEach((field) => setValue(field, result.data.provinsi[field]));
        } catch (err) {
            console.log(err.response.data);
        }
    };

   

    const onSubmit = async (data) => {
        let dataJSON = JSON.stringify(data, (k, v) =>
            v && typeof v === 'object' ? v : '' + v
        );
        let payload = JSON.parse(dataJSON);


        const result = await axios.post(
            'https://asaonliserver.my.id/admin/provinsi/edit',
            JSON.stringify(payload)
        );
        console.log(result.data);

        navigate(`/allprovinsi/${role}`);
    };

    return (
        <>
            {`${role}` === "d1065c01abfbfd0e6b04120cf4ad9e1f" && (
                <div className='filters-inputs'>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className='mb-3'>
                            <label htmlFor='' className='form-label'>
                                Nama
                            </label>
                            <input
                                type='text'
                                className='admin-filterinput'
                                id='namaprovinsi'
                                name='namaprovinsi'
                                {...register('namaprovinsi', { required: true })}
                            />
                        </div>

                        <button className='save-filters' type='submit'>
                            Save
                        </button>
                    </form>
                </div>
            )} 
        </>
    );

};

export default EditProvinsi