import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import gmaps from "../imgs/google-maps.png";
import wa from "../imgs/whatsapp.png";
import ig from "../imgs/instagram.png";

import "./DetailJasa.css";
import { useSwipeable } from "react-swipeable";

const Detailjasa = () => {
  let { id, role } = useParams();
  const [djasa, setdJasa] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchJasa = async () => {
      try {
        const result = await axios(`https://asaonliserver.my.id/jasa/${id}`);
        await setdJasa(result.data.jasa);
        setLoaded(true);
      } catch (err) {
        setErrorMessage(err.response.data);
      }
    };
    fetchJasa();
  }, []);

  const confirmDelete = async (id) => {
    const payload = {
      id: id.toString(),
    };
    await axios.post(
      "https://asaonliserver.my.id/admin/jasa/delete",
      JSON.stringify(payload)
    );
    navigate(`/jasaadmin/${role}`);
  };

  const whatsappUrl = `https://wa.me/${djasa.wa}`;
  const igUrl = `https://www.instagram.com/${djasa.ig}`;

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    setCurrentIndex(0);
  }, [djasa]);

  const imageKeys = Object.keys(djasa.imagejasa || []);

  const goToPrevSlide = () => {
    setCurrentIndex((prevIndex) => {
      if (imageKeys.length === 0) return 0;
      return (prevIndex - 1 + imageKeys.length) % imageKeys.length;
    });
  };

  const goToNextSlide = () => {
    setCurrentIndex((prevIndex) => {
      if (imageKeys.length === 0) return 0;
      return (prevIndex + 1) % imageKeys.length;
    });
  };
  const handlers = useSwipeable({
    onSwipedLeft: goToNextSlide,
    onSwipedRight: goToPrevSlide,
  });

  return (
    <>
    <button className="back-button" onClick={() => navigate(-1)}>
            ← Back
          </button>
      {!loaded ? (
        (() => {
          if (errorMessage) {
            return (
              <div className="kateop">
                <p>Oopss...</p>
              </div>
            );
          } else {
            return (
              <div className="atas">
                <p>Loading...</p>
              </div>
            );
          }
        })()
      ) : (
        
        <div className="detail-jasa">
          
          <div className="image-container" {...handlers}>
            {imageKeys.map((key, index) => (
              <img
                key={key}
                src={`https://asaonliserver.my.id/uploads/${djasa.imagejasa[key]}`}
                alt={`Image ${key}`}
                className={index === currentIndex ? "active" : ""}
              />
            ))}
            <button className="prev" onClick={goToPrevSlide}>
              ❮
            </button>
            <button className="next" onClick={goToNextSlide}>
              ❯
            </button>
          </div>
          <div className="text-container">
            <h1 className="judul-detail">{djasa.title}</h1>
            <div className="alamat-container">
            <p className="alamat-jasa">Lokasi :</p>
            <a href={djasa.alamat} target="_blank">
              <img
                src={gmaps}
                alt="gmaps"
                className="image-icondgmaps"
                loading="lazy"
              />
            </a>
            </div>
            <p className="deskripsi-jasa">Deskripsi</p>
            <p className="detail-kata-desc">{djasa.description}</p>
            <p className="detail-kata">{djasa.harga}</p>
            <a href={whatsappUrl} target="_blank">
              <img
                src={wa}
                alt="wa"
                className="image-icondetail"
                loading="lazy"
              />
            </a>
            <a href={igUrl} target="_blank">
              <img
                src={ig}
                alt="ig"
                className="image-icondetail"
                loading="lazy"
              />
            </a>
          </div>

          {`${role}` === "d1065c01abfbfd0e6b04120cf4ad9e1f" && (
            <div className="kategori-menuadmin">
              <div className="relasi-detail">
                {Object.entries(djasa.kategori).map(([key, value]) => (
                  <h2 key={key} className="drelasi-kata">
                    {value}
                  </h2>
                ))}
                {Object.entries(djasa.subkategori).map(([key, value]) => (
                  <h2 key={key} className="drelasi-kata">
                    {value}
                  </h2>
                ))}
                {Object.entries(djasa.provinsi).map(([key, value]) => (
                  <h2 key={key} className="drelasi-kata">
                    {value}
                  </h2>
                ))}
                {Object.entries(djasa.kota).map(([key, value]) => (
                  <h2 key={key} className="drelasi-kata">
                    {value}
                  </h2>
                ))}
              </div>
              <div className="opsidetail">
                <ul>
                  <li>
                    <Link
                      to={`/editjasa/${djasa.id}/${role}`}
                      className="kategori-ebox"
                    >
                      Edit
                    </Link>
                    <Link
                      to={`/tambahrelasi/${djasa.id}/${role}`}
                      className="kategori-ebox"
                    >
                      Tambah Relasi
                    </Link>
                    <Link
                      to={`/editrelasi/${djasa.id}/${role}`}
                      className="kategori-ebox"
                    >
                      Edit Relasi
                    </Link>
                    <Link
                      to={`/tambahgambar/${djasa.id}/${role}`}
                      className="kategori-ebox"
                    >
                      Tambah Gambar
                    </Link>
                    <Link
                      to={`/editgambar/${djasa.id}/${role}`}
                      className="kategori-ebox"
                    >
                      Edit Gambar
                    </Link>
                    <span
                      className="kategori-ebox-delete"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        if (window.confirm("Are you sure?")) {
                          confirmDelete(djasa.id);
                        }
                      }}
                    >
                      Delete
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Detailjasa;
