import React from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';
import LazyLoad from 'react-lazyload';
import axios from 'axios';


import './Menu.css';

const MainJasa = () => {
    let { title } = useParams();
    let { idjkategori } = useParams();
    let { role } = useParams();
    let { title2, role2 } = useParams();

    const isSearchMode = !title;
    const isSearchMode2 = !title2;
    const isJasaKategori = !idjkategori;

    const [kategori, setKategori] = useState([]);
    const [subkategori, setSubKategori] = useState([]);
    const [provinsi, setProvinsi] = useState([]);
    const [kota, setKota] = useState([]);
    const [kategorioption, setKategoriOP] = useState([]);
    const [hjasa, sethJasa] = useState([]);

    const [loaded, setLoaded] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null)
    const { register, handleSubmit, setValue } = useForm();

    const [idprv, setIdprv] = useState('');
    const [idktg, setIdktg] = useState('');

    const [isDisabled, setIsDisabled] = useState(true);

    useEffect(() => {


        if (isSearchMode && isSearchMode2 && isJasaKategori) {
            const fetchJasa = async () => {
                try {
                    const result = await axios(`https://asaonliserver.my.id/jasa`);
                    await setKategoriOP(result.data.ajasa);
                    setLoaded(true);
                } catch (err) {
                    setErrorMessage(err.response.data)
                }
            };
            fetchJasa();
        } else if (!isSearchMode) {
            const fetchSearch = async () => {
                try {
                    const result5 = await axios(`https://asaonliserver.my.id/search/${title}`);
                    const ajasaData = result5.data.jasa;

                    if (ajasaData && ajasaData.length > 0) {
                        await setKategoriOP(ajasaData);
                        setLoaded(true);
                    } else {
                        setErrorMessage('Maaf, data tidak ada');
                    }

                } catch (err) {
                    setErrorMessage(err.response.data)
                }
            };
            fetchSearch();
        } else if (!isSearchMode2) {
            const fetchSearch = async () => {
                try {
                    const result10 = await axios(`https://asaonliserver.my.id/search/${title2}`);
                    const ajasaData = result10.data.jasa;

                    if (ajasaData && ajasaData.length > 0) {
                        await setKategoriOP(ajasaData);
                        setLoaded(true);
                    } else {
                        setErrorMessage('Maaf, data tidak ada');
                    }

                } catch (err) {
                    setErrorMessage(err.response.data)
                }
            };
            fetchSearch();
        } else if (!isJasaKategori) {
            const fetchSearch = async () => {
                try {
                    const result11 = await axios(`https://asaonliserver.my.id/kategori/${idjkategori}/ajasa`);
                    const ajasaData = result11.data.ajasa;

                    if (ajasaData && ajasaData.length > 0) {
                        await setKategoriOP(ajasaData);
                        setLoaded(true);
                    } else {
                        setErrorMessage('Maaf, data tidak ada');
                    }

                } catch (err) {
                    setErrorMessage(err.response.data)
                }
            };
            fetchSearch();
        }


        const fetchKategori = async () => {
            try {
                const result2 = await axios(`https://asaonliserver.my.id/kategori`);
                await setKategori(result2.data.kategori);
            } catch (err) {
                setErrorMessage(err.response.data)
            }
        };
        fetchKategori();


        const fetchProvinsi = async () => {
            try {
                const result6 = await axios(`https://asaonliserver.my.id/provinsi`);
                await setProvinsi(result6.data.provinsi);
            } catch (err) {
                setErrorMessage(err.response.data)
            }
        };
        fetchProvinsi();


    }, []);

    useEffect(() => {

        const fetchSubkategori = async () => {
            if (!idktg) return;
            try {
                const result8 = await axios(`https://asaonliserver.my.id/subkategori/${idktg}`);
                await setSubKategori(result8.data.asubkategori);
            } catch (err) {
                setErrorMessage(err.response.data)
            }
        };
        fetchSubkategori();
    }, [idktg]);

    useEffect(() => {

        const fetchKota = async () => {
            if (!idprv) return;
            try {
                const result9 = await axios(`https://asaonliserver.my.id/kota/${idprv}`);
                await setKota(result9.data.akota);
            } catch (err) {
                setErrorMessage(err.response.data)
            }
        };
        fetchKota();
    }, [idprv]);

    const handleChangeIdprv = (event) => {
        setIdprv(event.target.value);
    };

    const handleChangeIdktg = (event) => {
        setIdktg(event.target.value);
    };

    useEffect(() => {
        if (idktg !== '' && idprv !== '') {
            setIsDisabled(false);
        } else {
            setIsDisabled(true);
        }
    })


    const onSubmit = async (data) => {
        let dataJSON = JSON.stringify(data, (k, v) =>
            v && typeof v === 'object' ? v : '' + v
        );
        let payload = JSON.parse(dataJSON);
        payload.idktg = idktg;
        payload.idprv = idprv;
        try {
            const result3 = await axios.post(
                'https://asaonliserver.my.id/filter/jasa',
                JSON.stringify(payload)
            );
            const ajasaData = result3.data?.jasa;
            if (ajasaData && Array.isArray(ajasaData) && ajasaData.length > 0) {
                await setKategoriOP(ajasaData);
                setLoaded(true);
            } else {
                setErrorMessage('Maaf, data tidak ada');
            }
        } catch (err) {
            const errorMessage = err.response?.data || 'Terjadi kesalahan';
            setErrorMessage(errorMessage)
        }
    };


    const [judul, setSearchTerm] = useState('');

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };
    const handleSearchClick = () => {
        const lowercaseTitle = judul.toLowerCase();
        window.location.href = `/searchadmin/${lowercaseTitle}/${role}`;
    }

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 50;

    const lastIndex = currentPage * itemsPerPage;
    const firstIndex = lastIndex - itemsPerPage;
    const currentItems = kategorioption.slice(firstIndex, lastIndex);

    const totalPages = Math.ceil(kategorioption.length / itemsPerPage);

    const nextPage = () => {
        setCurrentPage(currentPage + 1);
    };

    const prevPage = () => {
        setCurrentPage(currentPage - 1);
    };

    const goToPage = (page) => {
        setCurrentPage(page);
    };

    let pageButtons = [];
    if (totalPages <= 4) {
        pageButtons = [...Array(totalPages).keys()].map((num) => (
            <button key={num} onClick={() => goToPage(num + 1)} style={{ color: 'white' }}>{num + 1}</button>
        ));
    } else {
        if (currentPage <= 2) {
            pageButtons = [...Array(3).keys()].map((num) => (
                <button key={num} onClick={() => goToPage(num + 1)} style={{ color: 'white' }}>{num + 1}</button>
            ));
            pageButtons.push(<button key="ellipsis" style={{ color: 'white' }}>...</button>);
            pageButtons.push(
                <button key={totalPages} onClick={() => goToPage(totalPages)} style={{ color: 'white' }}>{totalPages}</button>
            );
        } else if (currentPage >= totalPages - 1) {
            pageButtons.push(<button key={1} onClick={() => goToPage(1)} style={{ color: 'white' }}>1</button>);
            pageButtons.push(<button key="ellipsis" style={{ color: 'white' }}>...</button>);
            pageButtons = pageButtons.concat(
                [...Array(3).keys()].map((num) => (
                    <button key={totalPages - 2 + num} onClick={() => goToPage(totalPages - 2 + num)} style={{ color: 'white' }}>
                        {totalPages - 2 + num}
                    </button>
                ))
            );
        } else {
            pageButtons.push(<button key={1} onClick={() => goToPage(1)} style={{ color: 'white' }}>1</button>);
            pageButtons.push(<button key="ellipsis1" style={{ color: 'white' }}>...</button>);
        
            // Menentukan halaman pertama yang akan ditampilkan di tengah
            const middlePage = Math.max(1, currentPage - 1);
            const middlePageEnd = Math.min(totalPages - 1, currentPage + 1);
        
            pageButtons = pageButtons.concat(
                [...Array(middlePageEnd - middlePage + 1).keys()].map((num) => (
                    <button key={middlePage + num} onClick={() => goToPage(middlePage + num)} style={{ color: 'white' }}>
                        {middlePage + num}
                    </button>
                ))
            );
        
            pageButtons.push(<button key="ellipsis2" style={{ color: 'white' }}>...</button>);
            pageButtons.push(
                <button key={totalPages} onClick={() => goToPage(totalPages)} style={{ color: 'white' }}>{totalPages}</button>
            );
        }
    }


    return (
        <>
            <div className='Menu-utama'>
                {`${role}` === "d1065c01abfbfd0e6b04120cf4ad9e1f" && (
                    <div className='Tambah-jasa'>
                        <input
                            type="text"
                            id="search-input"
                            className="Search-admin"
                            placeholder="Search Admin"
                            value={judul}
                            onChange={handleSearchChange}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    handleSearchClick();
                                }
                            }}
                        />
                        <button className="search-button" onClick={handleSearchClick}>
                        </button>
                        <ul>
                            <li><Link to={`/tambahjasa/${role}`} className='tambah-jasaadmin'>Tambah Jasa</Link></li>
                        </ul>
                    </div>
                )}

                <div className="row">
                    <select id='idktg' className="option-filter" value={idktg} onChange={handleChangeIdktg}
                    >
                        <option value='0' disabled={idktg !== ''} className='katego-op'>Kategori</option>
                        {
                            kategori.map(kategoris => (
                                <option value={kategoris.id} key={kategoris.id} className='katego-op'>
                                    {kategoris.namakategori}
                                </option>
                            ))
                        }

                    </select>

                    <select id='idsktg' className="option-filter"
                        {...register('idsktg', { required: true })}>

                        <option value='0' className='katego-op'>Sub Kategori</option>
                        <option value='0' className='katego-op'>All</option>
                        {
                            subkategori.map(subkategoris => (
                                <option
                                    value={subkategoris.id}
                                    key={subkategoris.id}
                                    className='katego-op'
                                >
                                    {subkategoris.namasubkategori}
                                </option>
                            ))
                        }
                    </select>

                    <select id='idprv' className="option-filter" value={idprv} onChange={handleChangeIdprv}
                    >
                        <option value='0' disabled={idprv !== ''} className='katego-op'>Provinsi</option>
                        {
                            provinsi.map(provinsis => (
                                <option value={provinsis.id} key={provinsis.id} className='katego-op'>
                                    {provinsis.namaprovinsi}
                                </option>
                            ))
                        }

                    </select>

                    <select id='idkot' className="option-filter" {...register('idkot', { required: true })}
                    >

                        <option value='0' className='katego-op'>Kota</option>
                        <option value='0' className='katego-op'>All</option>

                        {
                            kota.map(kotas => (
                                <option value={kotas.id} key={kotas.id} className='katego-op'>
                                    {kotas.namakota}
                                </option>
                            ))
                        }

                    </select >

                    <button className='button-filter' type='submit' onClick={handleSubmit(onSubmit)} disabled={isDisabled}>
                        Cari
                    </button>
                </div>
            </div>
            {!loaded ? (
                (() => {
                    if (errorMessage) {
                        return (
                            <div className='kateop'>
                                <p>Maaf jasa sepertinya tidak ada atau salah</p>
                            </div>
                        );
                    } else {
                        return (
                            <div className='atas'>
                                <p>Loading...</p>
                            </div>
                        );
                    }
                })()
            ) : (
                <>
                    <div className="jasa-tampilanmenu">
                        {currentItems.map((kategorio, index) => (
                            <LazyLoadedComponent key={index} kategorio={kategorio} role={role} />
                        ))}
                    </div >
                    <div className='button-nextback'>
                        <button onClick={prevPage} disabled={currentPage === 1} style={{ color: 'white' }}>Back</button>
                        {pageButtons}
                        <button onClick={nextPage} disabled={currentPage === totalPages} style={{ color: 'white' }}>Next</button>
                    </div>
                </>

            )}

        </>
    );
};

const LazyLoadedComponent = ({ kategorio, role }) => {
    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.1,
    });

    const [shouldRenderImage, setShouldRenderImage] = useState(false);
    const [imageLoaded, setImageLoaded] = useState(false);

    useEffect(() => {
        if (inView) {
            setShouldRenderImage(true);
        } else {
            setShouldRenderImage(false);
            setImageLoaded(false);
        }
    }, [inView]);

    return (
        <div ref={ref} className='jasa-menu'>
            {inView && (
                <div className='kategori-menu'>
                    {`${role}` === "d1065c01abfbfd0e6b04120cf4ad9e1f" ? (
                        <ul>
                            <li >
                                <Link to={`/detail/${kategorio.id}/${role}`} className='kategori-box'>
                                    {shouldRenderImage && kategorio.imagejasa && Object.entries(kategorio.imagejasa).map(([key, value], index) => (
                                        <div className='gambar-edit' key={key}>
                                            {index === 0 && (
                                                <>
                                                    <LazyLoad height={200} offset={100}>
                                                        <img
                                                            src={`https://asaonliserver.my.id/uploads/${kategorio.imagejasa[key]}`}
                                                            className='image-jasaluar'
                                                            onLoad={() => setImageLoaded(true)}
                                                            onError={() => setImageLoaded(true)}
                                                            style={{ display: imageLoaded ? 'block' : 'none' }}
                                                        />
                                                    </LazyLoad>
                                                </>
                                            )}
                                        </div>
                                    ))}
                                    <p className='judul-jasa'>{kategorio.title}</p>
                                </Link></li>
                        </ul>
                    ) : (
                        <ul>
                            <li ><Link to={`/detail/${kategorio.id}`} className='kategori-box'>
                                {shouldRenderImage && kategorio.imagejasa && Object.entries(kategorio.imagejasa).map(([key, value], index) => (
                                    <div className='gambar-edit' key={key}>
                                        {index === 0 && (
                                            <>
                                                <LazyLoad height={200} offset={100}>
                                                    <img
                                                        src={`https://asaonliserver.my.id/uploads/${kategorio.imagejasa[key]}`}
                                                        className='image-jasaluar'
                                                        onLoad={() => setImageLoaded(true)}
                                                        onError={() => setImageLoaded(true)}
                                                    />
                                                </LazyLoad>
                                            </>
                                        )}
                                    </div>
                                ))}
                                <p className='judul-jasa'>{kategorio.title}</p>
                            </Link>
                            </li>
                        </ul>
                    )}
                </div>
            )}
        </div >
    )
};

export default MainJasa


