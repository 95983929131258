import React from 'react';

import { useForm } from 'react-hook-form';

import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

import './CRUD.css';

const TambahJasa = () => {

    let { role } = useParams();
    const { register, handleSubmit } = useForm();
    const navigate = useNavigate();

    const onSubmit = async (data) => {
        let dataJSON = JSON.stringify(data, (k, v) =>
            v && typeof v === 'object' ? v : '' + v
        );
        let payload = JSON.parse(dataJSON);


        const result = await axios.post(
            'https://asaonliserver.my.id/admin/jasa/add',
            JSON.stringify(payload)
        );
        console.log(result.data);

        // resetForm();
        navigate(`/jasaadmin/${role}`);
    };

    return (
        <>
            {`${role}` === "d1065c01abfbfd0e6b04120cf4ad9e1f" && (
                <div className='admin-edit'>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className='mb-3'>
                            <label htmlFor='' className='form-label'>
                                Nama
                            </label>
                            <input
                                type='text'
                                className='input-edit'
                                id='title'
                                name='title'
                                {...register('title', { required: true })}
                            />

                            <label htmlFor='' className='form-label'>
                                Alamat
                            </label>
                            <input
                                type='text'
                                className='input-edit'
                                id='alamat'
                                name='alamat'
                                {...register('alamat', { required: true })}
                            />


                            <label htmlFor='' className='form-label'>
                                Deskripsi
                            </label>
                            <textarea
                                rows={3}
                                className='input-edit-desc'
                                id='description'
                                name='description'
                                {...register('description', { required: true })}
                            />


                            <label htmlFor='' className='form-label'>
                                Harga
                            </label>
                            <input
                                type='text'
                                className='input-edit'
                                id='harga'
                                name='harga'
                                {...register('harga', { required: true })}
                            />


                            <label htmlFor='' className='form-label'>
                                WA
                            </label>
                            <input
                                type='text'
                                className='input-edit'
                                id='wa'
                                name='wa'
                                {...register('wa', { required: true })}
                            />


                            <label htmlFor='' className='form-label'>
                                IG
                            </label>
                            <input
                                type='text'
                                className='input-edit'
                                id='ig'
                                name='ig'
                                {...register('ig', { required: true })}
                            />
                            <button className='Save-button' type='submit'>
                                Save
                            </button>
                        </div>
                    </form>
                </div>
            )}
        </>
    );

};

export default TambahJasa