import React from 'react';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

const AllProvinsi = () => {

    let { role } = useParams();
    const [provinsi, setProvinsi] = useState([]);

    const [errorMessage, setErrorMessage] = useState(null)
    const [loaded, setLoaded] = useState(false);
    
    const fetchProvinsi = async () => {
        try {
            const result = await axios(`https://asaonliserver.my.id/provinsi`);
            if (result.data.provinsi !== null) {
                await setProvinsi(result.data.provinsi);
                setLoaded(true);
            } else {
                setErrorMessage('nothing data to load');
            }
        } catch (err) {
            setErrorMessage(err.response.data)
        }
    };
    


    useEffect(() => {
        fetchProvinsi();
    }, []);

    const confirmDelete = async (id) => {
        const payload = {
            id: id.toString(),
        };
        await axios.post(
            'https://asaonliserver.my.id/admin/provinsi/delete',
            JSON.stringify(payload)
        );
        fetchProvinsi();
        window.location.reload();
    };


    return (
        <>
            {`${role}` === "d1065c01abfbfd0e6b04120cf4ad9e1f" && (
                <div className="tambah-filter">
                    <ul>
                        <li><Link to={`/tambahprovinsi/${role}`} className='tambah-filteradmin'>Tambah Provinsi</Link></li>
                    </ul>
                </div>
            )}
            {!loaded ? (
                (() => {
                    if (errorMessage) {
                        return (
                            <div className='kateop'>
                                <p>Oopss...</p>
                            </div>
                        );
                    } else {
                        return (
                            <div className='atas'>
                                <p>Loading...</p>
                            </div>
                        );
                    }
                })()
            ) : (
                <div className='kateop'>
                    {`${role}` === "d1065c01abfbfd0e6b04120cf4ad9e1f" && (
                        <div className="filtersktg">
                            {
                                provinsi.map(provinsis => (
                                    <div className='filters-admin'>

                                        <ul>
                                            <li className='namafltrs-box'>{provinsis.namaprovinsi}</li>
                                            <li><Link to={`/editprovinsi/${provinsis.id}/${role}`} className='filters-box'>Edit</Link></li>
                                            <li>
                                                <span
                                                    className='filters-box'
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => {
                                                        if (window.confirm('Are you sure?')) {
                                                            confirmDelete(provinsis.id);
                                                        }
                                                    }}
                                                >
                                                    Delete
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                ))
                            }
                        </div>
                    )}
                </div>
            )}
        </>
    );

}
export default AllProvinsi