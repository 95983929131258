import React from 'react';
import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

const EditKategori = () => {

    let { id, role } = useParams();
    const { register, handleSubmit, setValue } = useForm();
    const navigate = useNavigate();
   
    const [imagekategori, setImageKategori] = useState('');
    const isAddMode = !id;
    const fields = [
        'id',
        'namakategori',
        'imagekategori',
    ];

    useEffect(() => {
        if (!isAddMode) {
            fetchKategori(id);
        }
    }, [isAddMode]);

    const handleImageChange = (e) => {
        const fileReader = new FileReader();
        fileReader.onload = (event) => {
            const base64String = event.target.result;
            setImageKategori(base64String);
        };
        fileReader.readAsDataURL(e.target.files[0]);
    };


    const fetchKategori = async (id) => {
        try {
            const result = await axios(`https://asaonliserver.my.id/onekategori/${id}`);

            fields.forEach((field) => setValue(field, result.data.kategori[field]));
        } catch (err) {
            console.log(err.response.data);
        }
    };



    const onSubmit = async (data) => {
        let dataJSON = JSON.stringify(data, (k, v) =>
            v && typeof v === 'object' ? v : '' + v
        );
        let payload = JSON.parse(dataJSON);
        payload.imagekategori = imagekategori;

        const result = await axios.post(
            'https://asaonliserver.my.id/admin/kategori/edit',
            JSON.stringify(payload)
        );
        console.log(result.data);

        navigate(`/allkategori/${role}`);
    };

    return (
        <>
            {`${role}` === "d1065c01abfbfd0e6b04120cf4ad9e1f" && (
                <div className='filters-inputs'>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className='mb-3'>
                            <label htmlFor='' className='form-label'>
                                Nama Kategori
                            </label>
                            <input
                                type='text'
                                className='admin-filterinput'
                                id='namakategori'
                                name='namakategori'
                                {...register('namakategori', { required: true })}
                            />
                        </div>
                        <div className='mb-3'>
                            <label htmlFor='gambar' className='form-label'>
                                Gambar
                            </label>
                            <input
                                type='file'
                                id='imagekategori'
                                name='imagekategori'
                                onChange={handleImageChange}
                            />
                        </div>

                        <button className='save-filters' type='submit'>
                            Save
                        </button>
                    </form>
                </div>
            )}
        </>
    );

};

export default EditKategori