import React from 'react';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import './admin-home.css';

const Admin = () => {

    let { role } = useParams();

    return (
        <>
            {`${role}` === "d1065c01abfbfd0e6b04120cf4ad9e1f" && (
                <div className='Menu-admin'>
                    <ul>
                        <li><Link to={`/jasaadmin/${role}`} className='admin-menu'>Jasa Admin</Link></li>
                        <li><Link to={`/allkategori/${role}`} className='admin-menu'>Jasa Kategori</Link></li>
                        <li><Link to={`/allsubkategori/${role}`} className='admin-menu'>Jasa Subkategori</Link></li>
                        <li><Link to={`/allprovinsi/${role}`} className='admin-menu'>Jasa Provinsi</Link></li>
                        <li><Link to={`/allkota/${role}`} className='admin-menu'>Jasa Kota</Link></li>
                    </ul>
                </div>
            )}
        </>
    );

};

export default Admin
