import React from 'react';
import { Link } from 'react-router-dom';

import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import axios from 'axios';

const Allsubkategori = () => {

    let { role } = useParams();
    const [subkategori, setSubKategori] = useState([]);

    const [errorMessage, setErrorMessage] = useState(null)
    const [loaded, setLoaded] = useState(false);

    const fetchSubKategori = async () => {
        try {
            const result = await axios(`https://asaonliserver.my.id/subkategori`);
            if (result.data.subkategori !== null) {
                await setSubKategori(result.data.subkategori);
                setLoaded(true);
            } else {
                setErrorMessage('nothing data to load');
            }
        } catch (err) {
            setErrorMessage(err.response.data)
        }
    };
    


    useEffect(() => {
        fetchSubKategori();
    }, []);

    const confirmDelete = async (id) => {
        const payload = {
            id: id.toString(),
        };
        await axios.post(
            `https://asaonliserver.my.id/admin/subkategori/delete`,
            JSON.stringify(payload)
        );
        fetchSubKategori();
        window.location.reload();
    };


    return (
        <>
            {`${role}` === "d1065c01abfbfd0e6b04120cf4ad9e1f" && (
                <div className="tambah-filter">
                    <ul>
                        <li><Link to={`/tambahsubkategori/${role}`} className='tambah-filteradmin'>Tambah Sub Kategori</Link></li>
                    </ul>
                </div>
            )}
            {!loaded ? (
                (() => {
                    if (errorMessage) {
                        return (
                            <div className='kateop'>
                                <p>Oopss...</p>
                            </div>
                        );
                    } else {
                        return (
                            <div className='atas'>
                                <p>Loading...</p>
                            </div>
                        );
                    }
                })()
            ) : (
                <div className='kateop'>
                    {`${role}` === "d1065c01abfbfd0e6b04120cf4ad9e1f" && (
                        <div className="filtersktg">
                            {
                                subkategori.map(subkategoris => (
                                    <div className='filters-admin'>
                                        <ul>
                                            <li className='namafltrs-box'> {subkategoris.namasubkategori}</li>
                                            <li><Link to={`/editsubkategori/${subkategoris.id}/${role}`} className='filters-box'>Edit</Link></li>
                                            <li>
                                                <span
                                                    className='filters-box'
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => {
                                                        if (window.confirm('Are you sure?')) {
                                                            confirmDelete(subkategoris.id);
                                                        }
                                                    }}
                                                >
                                                    Delete
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                ))
                            }
                        </div>
                    )}
                </div>
            )}
        </>
    );

}
export default Allsubkategori