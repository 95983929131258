import React from 'react';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react'
import axios from 'axios';

import './Menu.css';

const Allkategori = () => {

    const [kategori, setKategori] = useState([]);

    const [errorMessage, setErrorMessage] = useState(null)
    const [loaded, setLoaded] = useState(false);



    useEffect(() => {
        const fetchKategori = async () => {
            try {
                const result = await axios(`https://asaonliserver.my.id/kategori`);
                if (result.data.kategori !== null) {
                    await setKategori(result.data.kategori);
                    setLoaded(true);
                } else {
                    setErrorMessage('nothing data to load');
                }
            } catch (err) {
                setErrorMessage(err.response.data)
            }
        };
        fetchKategori();
    }, []);


    return (
        <>
            {!loaded ? (
                (() => {
                    if (errorMessage) {
                        return (
                            <div className='kateop'>
                                <p>Oopss...</p>
                            </div>
                        );
                    } else {
                        return (
                            <div className='atas'>
                                <p>Loading...</p>
                            </div>
                        );
                    }
                })()
            ) : (
                <div className='All-jasaktg'>
                    <div className="kategori-style">
                        {
                            kategori.map(kategoris => (
                                <ul>
                                    <li><Link to={`/jasakategori/${kategoris.id}`} className='kategori-box'>
                                        <img src={kategoris.imagekategori} alt="tesgambar" className="image-kategori" loading='lazy' />
                                        {kategoris.namakategori}</Link></li>
                                </ul>
                            ))
                        }
                    </div>
                </div>
            )}
        </>
    );

}
export default Allkategori