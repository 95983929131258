import React from 'react';
import { Routes, Route } from 'react-router-dom';

import Menu from './Menu';
import Search from './MainJasa';
import JasaAdmin from './MainJasa';
import JasaKategori from './MainJasa';
import KategoriAll from './Kategoriall';
import Detail from '../pages/detailjasa/detail';
import DetailJasaAdmin from '../pages/detailjasa/detail';
import Login from '../pages/Login/login';

import TambahRelasi from '../pages/CRUD/TambahRelasi';
import EditRelasi from '../pages/CRUD/EditRelasi';
import Admin from '../pages/Admin/admin';

import TambahGambar from '../pages/CRUD/TambahImage';
import EditGambar from '../pages/CRUD/EditImage';
import EditGambarJasa from '../pages/CRUD/EditGambarJasa';

import AllKategori from '../pages/Filter/AllKategori';
import AllSubKategori from '../pages/Filter/AllSubkategori';
import AllProvinsi from '../pages/Filter/AllProvinsi';
import AllKota from '../pages/Filter/AllKota';

import TambahJasa from '../pages/CRUD/TambahJasa';
import TambahKategori from '../pages/CRUD/TambahKategori';
import TambahSubKategori from '../pages/CRUD/TambahSubkategori';
import TambahProvinsi from '../pages/CRUD/TambahProvinsi';
import TambahKota from '../pages/CRUD/TambahKota';

import EditJasa from '../pages/CRUD/EditJasa';
import EditKategori from '../pages/CRUD/EditKategori';
import EditSubKategori from '../pages/CRUD/EditSubKategori';
import EditProvinsi from '../pages/CRUD/EditProvinsi';
import EditKota from '../pages/CRUD/EditKota';

function Tes() {
    return (
        <Routes>
            <Route path="/" element={<Menu />}/>
            <Route exact path='/more' element={<KategoriAll />} />
            <Route exact path='/detail/:id' element={<Detail />} />
            <Route exact path='/detail/:id/:role' element={<DetailJasaAdmin />} />
            <Route exact path='/jasakategori/:idjkategori' element={<JasaKategori />} />
            
            <Route exact path='/search/:title' element={<Search />} />
            <Route exact path='/searchadmin/:title2/:role' element={<Search />} />
            <Route exact path='/login' element={<Login />} />
            <Route path='/admin/:role' element={<Admin />} />
            <Route path='/jasaadmin/:role' element={<JasaAdmin />} />
            
            <Route path='/tambahrelasi/:id/:role' element={<TambahRelasi />} />
            <Route path='/editrelasi/:id/:role' element={<EditRelasi />} />

            <Route path='/tambahgambar/:id/:role' element={<TambahGambar/>} />
            <Route path='/editgambar/:id/:role' element={<EditGambar/>} />
            <Route exact path='/editgambarjasa/:id/:role/:key' element={<EditGambarJasa/>} />

            <Route path='/allkategori/:role' element={<AllKategori />} />
            <Route path='/allsubkategori/:role' element={<AllSubKategori />} />
            <Route path='/allprovinsi/:role' element={<AllProvinsi />} />
            <Route path='/allkota/:role' element={<AllKota />} />

            <Route path='/tambahjasa/:role' element={<TambahJasa />} />
            <Route path='/tambahkategori/:role' element={<TambahKategori />} />
            <Route path='/tambahsubkategori/:role' element={<TambahSubKategori />} />
            <Route path='/tambahprovinsi/:role' element={<TambahProvinsi />} />
            <Route path='/tambahkota/:role' element={<TambahKota />} />

            <Route path='/editjasa/:id/:role' element={<EditJasa />} />
            <Route path='/editkategori/:id/:role' element={<EditKategori />} />
            <Route path='/editsubkategori/:id/:role' element={<EditSubKategori />} />
            <Route path='/editprovinsi/:id/:role' element={<EditProvinsi />} />
            <Route path='/editkota/:id/:role' element={<EditKota />} />

        </Routes>
    )
}
export default Tes;