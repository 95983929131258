import React from 'react';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import axios from 'axios';

import './Filter.css';

const Allkategori = () => {

    let { role } = useParams();
    const [kategori, setKategori] = useState([]);
    
    const [errorMessage, setErrorMessage] = useState(null)
    const [loaded, setLoaded] = useState(false);
    
    const fetchKategori = async () => {
        try {
            const result = await axios(`https://asaonliserver.my.id/kategori`);
            if (result.data.kategori !== null) {
                await setKategori(result.data.kategori);
                setLoaded(true);
            } else {
                setErrorMessage('nothing data to load');
            }
        } catch (err) {
            setErrorMessage(err.response.data)
        }
    };
    


    useEffect(() => {
        fetchKategori();
    }, []);

    const confirmDelete = async (id) => {
        const payload = {
          id: id.toString(),
        };
        await axios.post(
          `http://asaonliserver.my.id/admin/kategori/delete`,
          JSON.stringify(payload)
        );
        fetchKategori();
        window.location.reload();
      };
    

    return (
        <>
            {`${role}` === "d1065c01abfbfd0e6b04120cf4ad9e1f" && (
                <div className="tambah-filter">
                    <ul>
                        <li><Link to={`/tambahkategori/${role}`} className='tambah-filteradmin'>Tambah Kategori</Link></li>
                    </ul>
                </div>
            )}
            {!loaded ? (
                (() => {
                    if (errorMessage) {
                        return (
                            <div className='kateop'>
                                <p>Oopss...</p>
                            </div>
                        );
                    } else {
                        return (
                            <div className='atas'>
                                <p>Loading...</p>
                            </div>
                        );
                    }
                })()
            ) : (
                <div className='Filters-pages'>
                    {`${role}` === "d1065c01abfbfd0e6b04120cf4ad9e1f" && (
                        <div className="filtersktg">
                            {
                                kategori.map(kategoris => (
                                    <div className='filters-admin'>
                                        <ul>
                                            <li className='namafltrs-box'> {kategoris.namakategori}</li>
                                            <li><Link to={`/editkategori/${kategoris.id}/${role}` }  className='filters-box'>Edit</Link></li>
                                            <li>
                                                <span
                                                    className='filters-box'
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => {
                                                        if (window.confirm('Are you sure?')) {
                                                            confirmDelete(kategoris.id);
                                                        }
                                                    }}
                                                >
                                                    Delete
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                ))
                            }
                        </div>
                    )}
                </div>
            )}
        </>
    );

}
export default Allkategori