import { BrowserRouter as Router } from "react-router-dom";
import React, { useState, useEffect } from "react";

import "./App.css";
import Home from "./components/Home";

import logo from "./img/logo.png";

function App() {
  const [title, setSearchTerm] = useState("");

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };
  const handleSearchClick = () => {
    const lowercaseTitle = title.toLowerCase();
    window.location.href = `/search/${lowercaseTitle}`;
  };

  useEffect(() => {
    const toggleNav = () => {
      const togglebtn = document.querySelector(".togglebtn");
      const nav = document.querySelector(".navlinks");
      const links = document.querySelectorAll(".navlinks li");

      togglebtn.classList.toggle("click");
      nav.classList.toggle("open");
      links.forEach((link) => link.classList.toggle("open"));
    };

    const togglebtn = document.querySelector(".togglebtn");
    togglebtn.addEventListener("click", toggleNav);

    return () => {
      togglebtn.removeEventListener("click", toggleNav);
    };
  }, []);

  return (
    <Router>
      <div className="App">
        <header>
          <div className="logomain">
            <a href="/" className="logohome">
              <img src={logo} alt="Logo" />
              <h1>asa</h1>
            </a>
          </div>

          <input
            type="text"
            id="search-input"
            className="Searchutama"
            placeholder="Search..."
            value={title}
            onChange={handleSearchChange}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                handleSearchClick();
              }
            }}
          />
          <button className="search-icon" onClick={handleSearchClick}></button>

          <nav>
            <div className="togglebtn">
              <span></span>
              <span></span>
              <span></span>
            </div>
            <ul className="navlinks">
              <li>
                <a href="/">Home</a>
              </li>
              <li>
                <a href="/more">Jasa</a>
              </li>
              <li>
                <a href="#">About</a>
              </li>
              <li>
                <a href="#">Contact</a>
              </li>
            </ul>
          </nav>
        </header>
        <div className="kategori">
          <Home />
        </div>
      </div>
    </Router>
  );
}

export default App;
