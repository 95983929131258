import React from 'react';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react'
import axios from 'axios';
import './Menu.css';
import Menugambar from '../img/Gunungasa.png';



import etc from '../img/etc.png';


const Menu = () => {

  const [kategori, setKategori] = useState([]);

  const [errorMessage, setErrorMessage] = useState(null)
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const fetchKategori = async () => {
      try {
        const result = await axios(`https://asaonliserver.my.id/kategori`);
        if (result.data.kategori !== null) {
          await setKategori(result.data.kategori);
          setLoaded(true);
        } else {
          setErrorMessage('nothing data to load');
        }
      } catch (err) {
        setErrorMessage(err.response.data)
      }
    };
    fetchKategori();
  }, []);


  return (
    <>
      <div className='Awal'>
        <div class="content">
          <h2 className='Judulawal'>Cari Jasa?</h2>
          <h3 className='Judulkedua'>asa tempatnya</h3>
        </div>
        <img src={Menugambar} alt="Menugambar" className="image" />
      </div>
      {!loaded ? (
        (() => {
          if (errorMessage) {
            return (
              <div className='kateop'>
                <p>Oopss...</p>
              </div>
            );
          } else {
            return (
              <div className='atas'>
                <p>Loading...</p>
              </div>
            );
          }
        })()
      ) : (
        <div className="halaman-kategori">
          <div className='kategori-style' >
            {
              kategori.slice(0, 9).map(kategoris => (
                <ul>
                  <li>
                    <Link to={`/jasakategori/${kategoris.id}`} className='kategori-box'>
                      <img src={kategoris.imagekategori} alt="tesgambar" className="image-kategori" loading='lazy' />
                      {kategoris.namakategori}</Link>
                  </li>
                </ul>
              ))
            }
            <ul>
              <li><Link to={`/more`} className='kategori-box'>
                <img src={etc} alt="etc" className="image-kategori" loading='lazy' />
                Lainnya</Link></li>
            </ul>
          </div>
        </div>
      )}

    </>
  );

}
export default Menu