import React from 'react';
import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

import './Login.css';

const Login = () => {

    const navigate = useNavigate();
    const { register, handleSubmit, setValue } = useForm();

    const [errorMessage, setErrorMessage] = useState(null)
    const [login, setLogin] = useState([]);


    const onSubmit = async (data) => {
        let dataJSON = JSON.stringify(data, (k, v) =>
            v && typeof v === 'object' ? v : '' + v
        );
        let payloadlogin = JSON.parse(dataJSON);
        try {
            const result = await axios.post(
                'https://asaonliserver.my.id/login',
                JSON.stringify(payloadlogin)
            );
            await setLogin(result.data.login);

            if (result.data.login.role === "d1065c01abfbfd0e6b04120cf4ad9e1f") {
                navigate(`/admin/${result.data.login.role}`);
            }
        } catch (err) {
            setErrorMessage(err.response.data)
        }
    };

    return (
        <>

            <div className="Login-box">
                <h2>Login</h2>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='logininput'>
                        <input
                            type='text'
                            className='input-login'
                            placeholder="Username..."
                            id='username'
                            name='username'
                            {...register('username', { required: true })}
                        />
                        <input
                            type='password'
                            className='input-login'
                            id='password'
                            name='password'
                            placeholder="Password..."
                            {...register('password', { required: true })}

                        />
                        <button className='button-login' type='submit'>
                            Login
                        </button>
                    </div>
                </form>
            </div>


        </>
    );

};

export default Login