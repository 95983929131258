import React from 'react';

import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

const TambahImage = () => {

    let { id, role } = useParams();
    const { handleSubmit, setValue } = useForm();
    const navigate = useNavigate();

    const [djasa, setdJasa] = useState([]);
    const [imagejasa, setImageJasa] = useState('');
    const isAddMode = !id;
    const fields = [
        'id',
    ];

    useEffect(() => {
        if (!isAddMode) {
            fetchJasa(id);
        }
    }, [isAddMode]);


    const fetchJasa = async (id) => {
        try {
            const result = await axios(`https://asaonliserver.my.id/jasa/${id}`);

            await setdJasa(result.data.jasa);
            fields.forEach((field) => setValue(field, result.data.jasa[field]));
        } catch (err) {
            console.log(err.response.data);
        }
    };


    const loadFile = (e) => {
        const file = e.target.files[0];
        setImageJasa(file);
    };


    const onSubmit = async (data) => {
        const formData = new FormData();
        formData.append('imagejasa', imagejasa);
        formData.append('jasa_id', djasa.id);

        try {
        const result6 = await axios.post(
            'https://asaonliserver.my.id/admin/gambar/add', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            console.log(result6.data);
        } catch (err) {
            console.log(err.result6.data);
        }


        navigate(`/detail/${id}/${role}`);
    };

    return (
        <>
            {`${role}` === "d1065c01abfbfd0e6b04120cf4ad9e1f" && (
                <div className='relasi-u'>
                    <h2>Tambah Gambar</h2>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <h2>{djasa.title}</h2>

                        <div className='mb-3'>
                            <label htmlFor='gambar' className='form-label'>
                                Gambar
                            </label>
                            <input
                                type='file'
                                id='imagejasa'
                                name='imagejasa'
                                onChange={loadFile}
                            />
                        </div>
                        <button className='button-filter' type='submit'>
                            Save
                        </button>

                    </form>
                </div>
            )}
        </>
    );

};

export default TambahImage